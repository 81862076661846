@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  
  html, body, #root {
    height: 100%;
    min-height: 100%;
  }
  
  body {
    -webkit-font-smothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }

  body, input, button {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 14px;
  }

  #root {
    height: 100%;
  }

